<template>
	<div>
		<template v-for="(list,i) in items">
      <!-- TITULO -->
				<v-row :key="i">
					<v-col cols="12" class="pt-1 pb-0">
						<span class="title-letter">{{ list.category | lang($store.getters.language)}}</span>
					</v-col>
				</v-row>
			<template v-if="list.dishes.length">
				<!-- ITEMS CARTA -->
				<v-row class="box-letter" :key="i + '-dishe'">
					<v-col cols="6" md="6" lg="4" class="pt-1" v-for="(dishe, i) in list.dishes" :key="i">
						<card-dishe-letter :item="dishe" type="dishe"/>
					</v-col>
				</v-row>
			</template>

      <template v-else>
        No hay Platos
      </template>

		</template>
	</div>
</template>
<script>
import CardDisheLetter from './CardDisheLetter'
	export default{
		name: 'Dishes',
		components: { CardDisheLetter },
		props:{
			items: {
        type: Array,
        default: () => ([]),
        required: true,
      }

    },
    filters:{
    	lang(val, lang){
    		return val[lang] ? val[lang] : val.es;
    	}
    }
	}
</script>
