<template>
	<v-container fluid class="px-1">
		<v-row class="box-letter" v-if="load_letters">
			<v-col cols="6" sm="4" md="4" lg="4" class="pt-1" v-for="n in 4" :key="n">
				<v-sheet class="">
		      <v-responsive class="mx-auto">
		        <v-skeleton-loader
		          ref="skeleton"
		          type="card"
		          height="130"
		          class="mx-auto"
		        ></v-skeleton-loader>
		      </v-responsive>
		    </v-sheet>
			</v-col>
		</v-row>
		<template v-else>
			<v-row no-gutters v-if="$vuetify.breakpoint.mdAndUp" class="my-3">
        <v-col cols="12">
        	<v-menu :close-on-content-click="false" offset-x>
        		<template v-slot:activator="{ on }">
		          <v-btn depressed outlined rounded color="landing_primary" class="mr-3" v-on="on">
		            <span v-text="$t('default.btns.secciones')"></span>
		            <v-icon small>mdi-chevron-down</v-icon>
		          </v-btn>
        		</template>
      			<v-card class="pa-0 ma-0 card-actions" width="500">
      		 		<v-row class="px-8">
								<v-col cols="6" class="pb-0">
									<h3 v-text="$t('default.titulos.secciones')"></h3>
									<base-divider color="landing_primary" dense align="left" max-width="35" space="1" />
								</v-col>
								<v-col cols="6" class="d-flex justify-end pb-0" style="padding-top: 6px">
									<v-btn text color="landing_primary" class="font-weight-bold text-capitalize all" style="padding-left: 5px !important; padding-right: 5px !important" @click="selectAllCategory">
										<v-icon color="landing_primary" left small>mdi-brightness-1</v-icon>
										<span class="black--text" v-text="$t('default.btns.select_todos')"></span>
									</v-btn>
								</v-col>
							</v-row>
        			<v-list flat>
        				<v-list-item-group v-model="model_category" color="landing_primary" multiple  @change="filtersDishes">
        					<v-row no-gutters>
        						<v-col cols="6"  v-for="(item, i) in categories" :key="i">
						          <v-list-item :value="item.id">
						          	<template v-slot:default="{ active }">
							            <v-list-item-icon class="mr-1 mt-4">
								            <v-icon small v-if="!active" v-text="'mdi-checkbox-blank-circle-outline'"></v-icon>
								            <v-icon small v-else color="landing_primary" v-text="'mdi-checkbox-blank-circle'"></v-icon>
								          </v-list-item-icon>
						          		<v-list-item-content>
								            <v-list-item-title class="font-weight-bold">
														  <span class="ml-1">{{ item.name | lang }}</span>
								            </v-list-item-title>
								          </v-list-item-content>
						          	</template>
						          </v-list-item>
					          </v-col>
				          </v-row>
			        	</v-list-item-group>
			        </v-list>
        		</v-card>
	        </v-menu>
        	<v-menu :close-on-content-click="false" offset-x>
        		 	<template v-slot:activator="{ on }">
			          <v-btn depressed outlined rounded color="landing_primary"  v-on="on">
			            <span v-text="$t('default.btns.alergenos')"></span>
			            <v-icon small>mdi-chevron-down</v-icon>
			          </v-btn>
        		 	</template>
        		 	<v-card class="pa-0 ma-0 card-actions" width="500">
        		 		<v-row class="px-8">
									<v-col cols="6" class="pb-0">
										<h3 v-text="$t('default.titulos.alergenos')"></h3>
										<base-divider color="landing_primary" dense align="left" max-width="35" space="1" />
									</v-col>
									<v-col cols="6" class="d-flex justify-end pb-0" style="padding-top: 6px">
										<v-btn text color="landing_primary" class="font-weight-bold text-capitalize all" style="padding-left: 5px !important; padding-right: 5px !important" @click="selectAllAllergens">
											<v-icon color="landing_primary" left small>mdi-brightness-1</v-icon>
											<span class="black--text" v-text="$t('default.btns.select_todos')"></span>
										</v-btn>
									</v-col>
								</v-row>
	        			<v-list flat>
	        				<v-list-item-group v-model="selected_allergens" color="landing_primary" multiple @change="filtersDishes">
	        					<v-row no-gutters>
	        						<v-col cols="6"  v-for="(item, i) in allergens" :key="i">
							          <v-list-item :value="item.id">
							          	<template v-slot:default="{ active }">
								            <v-list-item-icon class="mr-1 mt-4">
									            <v-icon small v-if="!active" v-text="'mdi-checkbox-blank-circle-outline'"></v-icon>
									            <v-icon small v-else color="landing_primary" v-text="'mdi-checkbox-blank-circle'"></v-icon>
									          </v-list-item-icon>
							          		<v-list-item-content>
									            <v-list-item-title class="font-weight-bold">
									            	<v-avatar size="25">
														      <img :src="url_allergen + item.img">
															  </v-avatar>
															  <span class="ml-3" v-text="item.name"></span>
									            </v-list-item-title>
									          </v-list-item-content>
							          	</template>
							          </v-list-item>
						          </v-col>
					          </v-row>
				        	</v-list-item-group>
				        </v-list>
	        		</v-card>
        	</v-menu>
        </v-col>
      </v-row>
			<dishes :items="listDishes" />
		</template>
		<!-- SECCION -->
		<v-dialog v-model="sections_modal" fullscreen hide-overlay transition="dialog-bottom-transition">
			<v-card class="card-actions">
				<v-card-text>
					<v-row>
						<v-col cols="6">
							<h3 v-text="$t('default.titulos.secciones')"></h3>
							<base-divider color="landing_primary" dense align="left" max-width="35" space="1" />
						</v-col>
						<v-col cols="6" class="d-flex justify-end" style="padding-top: 6px">
							<v-btn text color="black" class="font-weight-bold text-capitalize" style="padding-left: 2px !important; padding-right: 2px !important" @click="selectAllCategory">
								<v-icon color="landing_primary" left small>mdi-brightness-1</v-icon>
								<span v-text="$t('default.btns.select_todos')"></span>
							</v-btn>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-list flat rounded class="sections-list">
					      <v-list-item-group v-model="model_category" color="landing_primary" multiple>
					        <v-list-item
					          v-for="(category, i) in categories"
					          :key="i"
					          class="elevation-3 mb-3"
					          :value="category.id"
					        >
					        	<template v-slot:default="{ active }">
						          <v-list-item-icon>
						            <v-icon v-if="!active" v-text="'mdi-checkbox-blank-circle-outline'"></v-icon>
						            <v-icon v-else color="landing_primary" v-text="'mdi-checkbox-blank-circle'"></v-icon>
						          </v-list-item-icon>

						          <v-list-item-content>
						            <v-list-item-title class="font-weight-bold">{{ category.name | lang($store.getters.language)}}</v-list-item-title>
						          </v-list-item-content>
					        	</template>
					        </v-list-item>
					      </v-list-item-group>
					    </v-list>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="8">
							<v-btn depressed block rounded color="landing_primary" @click="succesFilter('sections')">
								<span v-text="$t('default.btns.aplicar')"></span>
							</v-btn>
						</v-col>
					</v-row>

				</v-card-text>
			</v-card>
		</v-dialog>

				<!-- ALERGENOS -->
		<v-dialog v-model="allergens_modal" fullscreen hide-overlay transition="dialog-bottom-transition">
			<v-card class="card-actions">
				<v-card-text>
					<v-row>
						<v-col cols="6">
							<h3 v-text="$t('default.titulos.alergenos')"></h3>
							<base-divider color="landing_primary" dense align="left" max-width="35" space="1" />
						</v-col>
						<v-col cols="6" class="d-flex justify-end" style="padding-top: 6px">
							<v-btn text color="landing_primary" class="font-weight-bold text-capitalize all" style="padding-left: 5px !important; padding-right: 5px !important" @click="selectAllAllergens">
								<v-icon color="landing_primary" left small>mdi-brightness-1</v-icon>
								<span class="black--text" v-text="$t('default.btns.select_todos')"></span>
							</v-btn>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="12">
							<v-item-group multiple v-model="selected_allergens">
								<v-row>
					        <v-col v-for="(allergen, i) in allergens" :key="i" cols="4" class="px-1">
					        	<v-item v-slot:default="{ active, toggle }" :value="allergen.id">
					        		<v-card color="transparent" class="card-allergens" raised height="115" @click="toggle">
					        			<v-icon small class="px-2 pt-2" v-if="!active" v-text="'mdi-checkbox-blank-circle-outline'"></v-icon>
						            <v-icon small class="px-2 pt-2" v-else color="landing_primary" v-text="'mdi-checkbox-blank-circle'"></v-icon>
						            <v-card-text class="d-flex align-center justify-center py-1">
						            	<v-row no-gutters>
						            		<v-col cols="12 d-flex justify-center">
								            	<v-avatar size="45">
													      <img :src="url_allergen + allergen.img">
														  </v-avatar>
						            		</v-col>
						            		<v-col cols="12 d-flex justify-center" class="text-center">
												  		<h6 style="line-height: 1rem; padding-top: 5px" v-text="allergen.name"></h6>
						            		</v-col>
						            	</v-row>
						            </v-card-text>
					        		</v-card>
					        	</v-item>
					        </v-col>
					      </v-row>
							</v-item-group>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="8">
							<v-btn depressed block rounded color="landing_primary" @click="succesFilter('allergens')">
								<span v-text="$t('default.btns.aplicar')"></span>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>


	  <v-bottom-navigation horizontal fixed v-if="$vuetify.breakpoint.smAndDown" style="bottom:64px">
      <v-btn text class="text-capitalize pl-3 pr-0" @click="sections_modal = true">
        <v-icon style="margin-top: 2px">mdi-chevron-down</v-icon>
          <span class="px-2" v-text="$t('default.btns.secciones')"></span>
        <v-avatar color="landing_primary" size="24">
          <v-icon small color="white">mdi-silverware-fork-knife</v-icon>
        </v-avatar>
      </v-btn>
      <v-btn text class="text-capitalize pl-3 pr-0" @click="allergens_modal = true">
        <v-icon style="margin-top: 2px">mdi-chevron-down</v-icon>
          <span class="px-2" v-text="$t('default.btns.alergenos')"></span>
        <v-avatar color="landing_primary" size="24">
          <v-icon small color="white">mdi-silverware-fork-knife</v-icon>
        </v-avatar>
      </v-btn>
    </v-bottom-navigation>
	</v-container>
</template>
<script>

import dishes from './components/dishes'
	export default{
		name: 'letter',
		components: { dishes },
		data(){
			return {
        sections_modal: false,
        allergens_modal: false,
        items: [
            {
              icon: 'mdi-brightness-1',
              text: 'Wifi',
            },
            {
              icon: 'mdi-bluetooth',
              text: 'Bluetooth',
            },
            {
              icon: 'mdi-chart-donut',
              text: 'Data Usage',
            },
          ],
          model_category: [],
          selected_allergens: [],
          error: '',
          load_letters: false,
          dishes: [],
          listDishes: [],
          allergens: [],
          categories: [],
          url_allergen: process.env.VUE_APP_IMG_ALERGENOS,
          shopping_cart: {dishes:[], menu:[]},
          coins:[]
      }
		},
		created(){

      this.getLetters();
      // this.getAllergens();
    },
    methods:{
      initCoins(){
        this.coins = JSON.parse(this.$store.getters["landing/coins"])
      },
      async getLetters(){
      	this.load_letters = true;
        const url = this.$route.params.landing ? this.$route.params.landing : '';
        try{
          const {data} = await this.$api.get(`api/restaurant/${url}/dishes`);
         	// this.dishes = data.data.restaurant.dishes;
         	this.allergens = data.data.restaurant.allergens
         	this.categories = data.data.restaurant.categories.map(item => {
         		return {
         			name: item.name,
         			id: item.id,
         			dishes: item.dishes.length
         		}
         	}).filter(item => item.dishes > 0);

         	this.dishes = data.data.restaurant.categories.map(item => {
         		return {
         			category: JSON.parse(item.name),
         			id: item.id,
         			dishes: item.dishes
         		}
         	});

         	this.selected_allergens = this.allergens.map(item => item.id);
         	this.model_category = this.categories.map(item => item.id);

         	this.filtersDishes();
         	this.load_letters = false;
           this.initCoins();
        }
        catch(e){
         this.error = e;
        }
      },
      selectAllAllergens(){
      	this.selected_allergens = this.allergens.map(item => item.id);
      	if(this.$vuetify.breakpoint.mdAndUp){
      		this.filtersDishes();
      	}
      },
      selectAllCategory(){
      	this.model_category = this.categories.map(item => item.id);
      	if(this.$vuetify.breakpoint.mdAndUp){
      		this.filtersDishes();
      	}
      },
      filtersDishes(){
      	// this.listDishes = [];
      	this.listDishes = this.dishes.map(item =>{
    			let platos = item.dishes.filter(dishe => {
    				return dishe.allergens.length > 0 ? dishe.allergens.every(allergen =>{
    					return this.selected_allergens.includes(allergen.id)
    				}) : true;
          });
          platos = platos.filter(dishe => {
            return dishe.visibility == 1? true : false;
          })
    			return {
    				category: item.category,
         		id: item.id,
         		dishes: platos
    			}
    		}).filter(item => this.model_category.includes(item.id));
      },
      succesFilter(type){
      	this[`${type}_modal`] = false;
      	this.filtersDishes();
      }
    },
    computed:{
    	top(){
    		return this.$vuetify.application.top;
    	},
    	letters(){
    		return this.dishes.map(item =>{
    			const platos = item.dishes.filter(dishe => {
    				return dishe.allergens.length > 0 ? dishe.allergens.every(allergen =>{
    					return this.selected_allergens.includes(allergen.id)
    				}) : true;
    			});
    			return {
    				category: item.category,
         		id: item.id,
         		dishes: platos
    			}
    		}).filter(item => this.model_category.includes(item.id));
    	}
    },
    filters:{
    	lang(item, lang){
    		const val = JSON.parse(item);
    		return val[lang] ? val[lang] : val.es;
    	}
    }
	}
</script>
<style lang="sass">

	.sections-list .v-list-item__icon
		margin: 16px 0
		margin-right: 1px !important

	.card-allergens
		border-radius: 20px !important

	.v-card--link:focus:before, .all.v-btn:hover::before
			opacity: 0 !important

	.action-letter
		bottom:0px
		position: fixed
		z-index: 100

	.card-actions
		&.v-card .v-divider
			border-color: transparent !important
</style>
